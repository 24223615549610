import React, { FunctionComponent } from "react";

import { Typography, Divider } from "antd";
import SEO from "../components/Seo";
import { DefaultLayout } from "../components/DefaultLayout";
import { ContentCard } from "../components/ContentCard";
import { colors } from "../theme/variables";
import styled from "@emotion/styled";

const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    color: ${colors.blue.primary};
  }
`;

const Impressum: FunctionComponent = () => {
  return (
    <>
      <SEO title={"Impressum"} />
      <DefaultLayout>
        <ContentCard title={<StyledTitle level={2}>Impressum</StyledTitle>}>
          <StyledTitle level={4}>Anschrift</StyledTitle>
          <Typography.Paragraph>
            horst ehlers architekt (baugewerblich)
          </Typography.Paragraph>
          <Typography.Paragraph>
            Rudolf-Hilferding-Straße 74, 60439 Frankfurt am Main
          </Typography.Paragraph>
          <Typography.Paragraph>
            Tel. +49 (0)69 65 00 86 91
          </Typography.Paragraph>
          <Typography.Paragraph>
            Fax: +49 (0)69 65 00 86 92
          </Typography.Paragraph>
          <Typography.Paragraph>
            Email: mail@architekt-horst-ehlers.de
          </Typography.Paragraph>
          <Typography.Paragraph>
            Internet: www.architekt-horst-ehlers.de
          </Typography.Paragraph>
          <Typography.Paragraph>USt. Idnr. DE 235715455</Typography.Paragraph>
          <Typography.Paragraph>Berufsbezeichnung</Typography.Paragraph>
          <Typography.Paragraph>Dipl. Ing. Horst Ehlers</Typography.Paragraph>
          <Typography.Paragraph>
            baugewerblicher Architekt gemäß dem Hessischen Architekten und
            Stadtplanergesetz (HASG)
          </Typography.Paragraph>

          <StyledTitle level={4}>
            Zuständige Behörde / Aufsichtsbehörde
          </StyledTitle>
          <Typography.Paragraph>
            Architekten- und Stadtplanerkammer Hessen
          </Typography.Paragraph>
          <Typography.Paragraph>
            Bierstadter Straße 2, 65189 Wiesbaden
          </Typography.Paragraph>
          <Typography.Paragraph>Tel. 0611 17380</Typography.Paragraph>
          <Typography.Paragraph>Fax. 0611 173840</Typography.Paragraph>
          <Typography.Paragraph>Email: info@akh.de</Typography.Paragraph>
          <Typography.Paragraph>Internet: www.akh.de</Typography.Paragraph>
          <Typography.Paragraph>
            Umsatzsteueridentifikationsnummer (§27a UStG)
          </Typography.Paragraph>
          <Typography.Paragraph>
            USt-Ident.- Nr. DE235715455
          </Typography.Paragraph>

          <StyledTitle level={4}>Berufsrechtliche Reglungen</StyledTitle>
          <Typography.Paragraph>
            Hessisches Architekten- und Stadtplanergesetz, Hauptsatzung der
            Architekten- und Stadtplanerkammer Hessen, Richtlinie zur
            Berufsordnung (Berufshaftpflicht), Fortbildungsordnung,
            Schlichtungsordnung.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Die berufsrechtlichen Regelungen können über die Homepage der
            Architekten- und Stadtplanerkammer (www.akh.de) unter der Rubrik
            „Recht“ eingesehen und abgerufen werden.
          </Typography.Paragraph>
          <Divider />
          <StyledTitle level={4}>
            Außergerichtliche Streitschlichtung
          </StyledTitle>
          <Typography.Paragraph>
            Zur gütlichen Beilegung von Streitigkeiten, die sich aus der
            Berufsausübung von Berufsangehörigen und Berufsgesellschaften
            zwischen diesen und Dritten ergeben, kann gemäß §7 der
            Schlichtungsordnung der Architekten- und Stadtplanerkammer Hessen
            auf Antrag ein Schlichtungsverfahren durchgeführt werden (www.akh.de
            / Schlichtung).
          </Typography.Paragraph>
          <Divider />
          <StyledTitle level={4}>Haftungsausschluss</StyledTitle>
          <StyledTitle level={4}>1. Inhalt des Onlineangebotes</StyledTitle>
          <Typography.Paragraph>
            Der Autor übernimmt keinerlei Gewähr für die Aktualität,
            Korrektheit, Vollständigkeit oder Qualität der bereitgestellten
            Informationen. Haftungsansprüche gegen den Autor, welche sich auf
            Schäden materieller oder ideeller Art beziehen, die durch die
            Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch
            die Nutzung fehlerhafter und unvollständiger Informationen
            verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens
            des Autors kein nachweislich vorsätzliches oder grob fahrlässiges
            Verschulden vorliegt. Alle Angebote sind freibleibend und
            unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der
            Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu
            verändern, zu ergänzen, zu löschen oder die Veröffentlichung
            zeitweise oder endgültig einzustellen.
          </Typography.Paragraph>
          <StyledTitle level={4}>2. Verweise und Links</StyledTitle>
          <Typography.Paragraph>
            Bei direkten oder indirekten Verweisen auf fremde Webseiten
            („Hyperlinks“), die außerhalb des Verantwortungsbereiches des Autors
            liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall
            in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und
            es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle
            rechtswidriger Inhalte zu verhindern. Der Autor erklärt hiermit
            ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen
            Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die
            aktuelle und zukünftige Gestaltung, die Inhalte oder die
            Urheberschaft der verlinkten/verknüpften Seiten hat der Autor
            keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich
            von allen Inhalten aller verlinkten /verknüpften Seiten, die nach
            der Linksetzung verändert wurden. Diese Feststellung gilt für alle
            innerhalb des eigenen Internetangebotes gesetzten Links und Verweise
            sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern,
            Diskussionsforen, Linkverzeichnissen, Mailinglisten und in allen
            anderen Formen von Datenbanken, auf deren Inhalt externe
            Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder
            unvollständige Inhalte und insbesondere für Schäden, die aus der
            Nutzung oder Nichtnutzung solcherart dargebotener Informationen
            entstehen, haftet allein der Anbieter der Seite, auf welche
            verwiesen wurde, nicht derjenige, der über Links auf die jeweilige
            Veröffentlichung lediglich verweist.
          </Typography.Paragraph>
          <StyledTitle level={4}>3. Urheber- und Kennzeichenrecht</StyledTitle>
          <Typography.Paragraph>
            Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der
            verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu
            beachten, von ihm selbst erstellte Grafiken, Tondokumente,
            Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken,
            Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle
            innerhalb des Internetangebotes genannten und ggf. durch Dritte
            geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den
            Bestimmungen des jeweils gültigen Kennzeichenrechts und den
            Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein
            aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass
            Markenzeichen nicht durch Rechte Dritter geschützt sind! Das
            Copyright für veröffentlichte, vom Autor selbst erstellte Objekte
            bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder
            Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte
            in anderen elektronischen oder gedruckten Publikationen ist ohne
            ausdrückliche Zustimmung des Autors nicht gestattet.
          </Typography.Paragraph>
          <StyledTitle level={4}>4. Datenschutz</StyledTitle>
          <Typography.Paragraph>
            Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe
            persönlicher oder geschäftlicher Daten (Emailadressen, Namen,
            Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens
            des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme
            und Bezahlung aller angebotenen Dienste ist – soweit technisch
            möglich und zumutbar – auch ohne Angabe solcher Daten bzw. unter
            Angabe anonymisierter Daten oder eines Pseudonyms gestattet. Die
            Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben
            veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und
            Faxnummern sowie Emailadressen durch Dritte zur Übersendung von
            nicht ausdrücklich angeforderten Informationen ist nicht gestattet.
            Rechtliche Schritte gegen die Versender von sogenannten Spam-Mails
            bei Verstössen gegen dieses Verbot sind ausdrücklich vorbehalten.
          </Typography.Paragraph>
          <StyledTitle level={4}>
            5. Rechtswirksamkeit dieses Haftungsausschlusses
          </StyledTitle>
          <Typography.Paragraph>
            Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
            betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern
            Teile oder einzelne Formulierungen dieses Textes der geltenden
            Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen
            sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt
            und ihrer Gültigkeit davon unberührt.
          </Typography.Paragraph>
        </ContentCard>
      </DefaultLayout>
    </>
  );
};

export default Impressum;
